import React, {  } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, styled } from "@mui/material";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


// import required modules
import { Navigation } from "swiper";

const Image = styled('img')({
    width:"100%",
    objectFit: "cover"
})

export default function Slider() {
  return (
      <Box>
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide>
            <Box className="overlay">
                <Image
                src="https://images.pexels.com/photos/4393533/pexels-photo-4393533.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="slider"
                sx={{height:{md:"700px",xs:"500px"}}}
                />
            </Box>
        </SwiperSlide>
        <SwiperSlide>
            <Box className="overlay">
                <Image
                src="https://images.pexels.com/photos/4226269/pexels-photo-4226269.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="slider"
                sx={{height:{md:"700px",xs:"500px"}}}
                />
            </Box>
        </SwiperSlide>
      </Swiper>
      </Box>
  );
}
