import { Box, Button, Container, Paper, Typography } from '@mui/material'
import React, { useRef } from 'react'
import ReactCodeInput from "react-code-input";
import {useNavigate} from 'react-router-dom'
import { useState } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const props = {
    inputStyle: {
      width: "50px",
      height: "50px",
      borderRadius: "4px",
      backgroundColor: "#eee",
      border: "none",
      outline: "none",
      fontSize: "24px",
      color: "#000000",
      fontWeight: "500",
      textAlign: "center",
      marginLeft:"10px"
    },
  }

export default function UserVerifyCode() {
    const input1 = useRef();
    const navigate = useNavigate()
    const [load,setLoad] = useState(false)

    async function confirmCode()
    {
        setLoad(true)
        try{
            if(input1.current.state.value.length!==4)
            {
                setLoad(false)
                throw new Error('failed occured')
            }
            const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/student/signup/code`,{
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({registerCode:input1.current.state.value,email:localStorage.getItem('studentEmail')})
            })
            const resData = await response.json()
            if(response.status!==200&&response.status!==201)
            {
                setLoad(false)
                throw new Error('failed occured')
            }
            localStorage.setItem('studentCode',true)
            navigate('/studentregister/step3')
            setLoad(false)
        }
        catch(err)
        {
            console.log(err)
        }
    }

    return (
        <>
            <Navbar/>
            <Container sx={{minHeight:"63vh" , marginTop:"120px" }}>
                <Paper sx={{width:{md:"450px"},padding:"30px 50px",margin:"60px auto 60px"}}>
                    <Typography variant='h5' marginBottom={"20px"} sx={{textAlign:"center"}}>أدخل رمز التحقق</Typography>
                        <Box
                        sx={{
                            display: "flex",
                            marginTop: "22px",
                            justifyContent: "center",
                            gap:"10px",
                            marginBottom:"20px"
                        }}                        
                        >
                            <ReactCodeInput type='text' ref={input1} fields={4} {...props}/>
                        </Box>
                        {!load?
                        <Button variant='contained'  fullWidth type='submit'
                        sx={{textTransform:"capitalize"}} 
                        onClick={confirmCode}>تاكيد الرمز</Button>
                        :
                        <Button variant='contained'  fullWidth
                        sx={{textTransform:"capitalize"}} 
                        onClick={()=>navigate('/studentregister/step3')}>جاري التاكيد...</Button>
                        }
                </Paper>
            </Container>
            <Footer/>
        </>
    )
}
