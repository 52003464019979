import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AdminLayout from '../../components/admin/AdminLayout'
import AcceptedOrders from '../../components/admin/AcceptedOrders';
import RejectedOrders from '../../components/admin/RejectedOrders';
import PendingOrders from '../../components/admin/PendingOrders';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AdminOrders() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <AdminLayout>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="الطلبات المقبولة" {...a11yProps(0)} />
            <Tab label="الطلبات المرفوضة" {...a11yProps(1)} />
            <Tab label="الطلبات المعلقة" {...a11yProps(2)} />
            </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
            <AcceptedOrders/>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <RejectedOrders/>
        </TabPanel>
        <TabPanel value={value} index={2}>
            <PendingOrders/>
        </TabPanel>
    </AdminLayout>
);
}