import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { IconButton  , Dialog} from '@mui/material';
import AdminLayout from '../../components/admin/AdminLayout';
import EditIcon from '@mui/icons-material/Edit';
import EditUserDialog from '../../components/admin/EditUserDialog';
import { useSelector } from 'react-redux';
import { useAllUsers } from '../../hooks/useAllUsers';

export default function AdminUser() {
    const columns = [
    { id: 'name_course', label:"اسم المستخدم", minWidth: 150 },
    { id: 'name_subject', label:"رقم الجوال", minWidth: 150 },
    { id: 'name_subject', label:"العنوان", minWidth: 150 },
    { id: 'name_subject', label:"طريقة التوصيل", minWidth: 150 },
    { id: 'update', label:"تعديل", minWidth: 150 }];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [openEdit , setOpenEdit] = useState(null);

    const {admin} = useSelector(s => s.admin);
    const {data } = useAllUsers(admin.token);

    // const users=[{id:1,name:"محمد",phone:"1111", address:"رياض", way:"داخل المدينة"},{id:2,name:"يوسف",phone:"2222", address:"رياض", way:"داخل المدينة"}]

    return (
    <AdminLayout>
        <Paper sx={{ width: '100%',padding:"20px"}}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                            key={column.id}
                            align={"center"}
                            style={{ top: 57, minWidth: column.minWidth }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    <TableBody>
                        {data?.users?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return <TableRow hover role="checkbox"  key={row.id+"demj"}>
                                <TableCell align='center'>
                                    {row.username}
                                </TableCell>
                                <TableCell align='center'>
                                    {row.phone}
                                </TableCell>
                                <TableCell align='center'>
                                    {row.address}
                                </TableCell>
                                <TableCell align='center'>
                                    {row.deliveryWay==="inside"?'داخل المدينة':"خارج المدينة"}
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton onClick={()=>setOpenEdit(row.id)}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                                <Dialog open={openEdit === row.id} onClose={()=>setOpenEdit(null)}>
                                    <EditUserDialog  handleClose={()=>setOpenEdit(null)} user={row}/>
                                </Dialog>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data?.users?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
        </Paper>
    </AdminLayout>
)
}