import { Button, DialogActions, DialogContent, Box, DialogTitle, Typography} from '@mui/material'
import React from 'react'


export default function OrderNotesDialog({handleClose,order}) {


    console.log(order);

  return (
    <Box sx={{width:"500px" , maxWidth:"100%"}}>
    <DialogTitle>ملاحظات الخاصة ب : {order.title}</DialogTitle>
        <DialogContent>
            <Box sx={{marginBottom:"20px"}}>
                {
                    order?.Notes?.map((note,index)=>{
                        return <Typography marginBottom={"12px"} key={note.id+"ekhn"}>{index+1} - {note.title}</Typography>
                    })
                }
            </Box>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="error">إغلاق</Button>
        </DialogActions>
    </Box>
  )
}
