import React from 'react'
import {Box,styled} from '@mui/material'
export default function LinesDesign() {

    const Wrapper = styled(Box)({
        display:"flex",
        justifyContent:"start",
        columnGap:"10px",
        marginTop:"15px"
    })

    const Span = styled("span")({
        height:"4px",
        borderRadius:"6px",
        backgroundColor:"#005B8E",
        display:"block",
    })
    return (
        <Wrapper>
            <Span sx={{width:"25px"}}></Span>
            <Span sx={{width:"60px"}}></Span>
            <Span sx={{width:"25px"}}></Span>
        </Wrapper>
    )
}