import { Box, Button, Container, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, styled, RadioGroup, Select, TextField, Typography } from '@mui/material'
import React ,{useRef}from 'react'
import { useForm, Controller } from "react-hook-form";
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Map from '../../components/Map';
import { useSnackbar } from 'notistack';



const Label = styled("label")({
    width:"100%",
    display:"block",
    padding:"6px 16px",
    cursor:"pointer"
})

const Image = styled('img')({
    width:"120px",
    height:"120px",
    borderRadius:"50%"
})


export default function UserRegister() {
    const navigate = useNavigate()
    const [image,setImage] = useState(null)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const mapRef = useRef(null);

;
    const [load,setLoad] = useState(false)
    const { register,control, watch,formState: { errors }, handleSubmit,reset } = useForm({
        defaultValues: {
            name:"",
            phone:"",
            password:"",
            city:'',
        }
    });




    async function onSubmit(data)
    {
        if(!image){
            enqueueSnackbar("الرجاء ارفاق صورة" ,{variant:"error" , autoHideDuration:2000});
            return;
        }
        closeSnackbar();
        setLoad(true)
        const address = mapRef.current.r;
        const formData = new FormData();
        formData.append('username' , data.name);
        formData.append('phone' , data.phone);
        formData.append('password' , data.password);
        formData.append('deliveryWay' , data.city);
        formData.append('address' , address);
        formData.append('image' , image);
        try{
            const response = await fetch(`${process.env.REACT_APP_API}api/v1/auth/register`,{
                method:"POST",
                body:formData
            })
            const resData = await response.json()
            if(response.status!==200&&response.status!==201)
            {
                enqueueSnackbar(resData.message,{variant:"error", autoHideDuration:2000})
                setLoad(false)
                throw new Error('failed occured')
            }
            enqueueSnackbar(resData.msg,{variant:"success", autoHideDuration:2000})
            navigate('/login')
            setLoad(false)
        }
        catch(err)
        {
            setLoad(false);
            console.log(err)
        }
    }


    function handleChangeImage(e)
    {
        setImage(e.target.files[0])
    }

    return (
        <>
        <Navbar/>
            <Container sx={{marginTop:"90px" ,minHeight:"63vh"}}>
                <Box sx={{padding:"30px 50px",margin:"30px auto 60px"}}>
                    <Typography variant='h5' marginBottom={"30px"}>إنشاء حساب</Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{marginBottom:"20px"}}>
                            <InputLabel sx={{marginBottom:"6px",fontSize:"13px"}}>الاسم</InputLabel>
                            <Controller
                            name="name"
                            control={control}
                            render={({ field }) => <TextField type="text" {...field} fullWidth/>}
                            {...register("name", { required: "fullName Address is required" })}
                            />
                            {errors.name?.type === 'required' && <Typography color="error" role="alert" sx={{fontSize:"13px",marginTop:"6px"}}>
                                هذا الحقل مطلوب</Typography>}
                        </Box>
                        <Box sx={{marginBottom:"20px"}}>
                            <InputLabel sx={{marginBottom:"6px",fontSize:"13px"}}>رقم الهاتف</InputLabel>
                            <Controller
                            name="phone"
                            control={control}
                            render={({ field }) => <TextField type="text" {...field} fullWidth/>}
                            {...register("phone", { required: "fullName Address is required" })}
                            />
                            {errors.phone?.type === 'required' && <Typography color="error" role="alert" sx={{fontSize:"13px",marginTop:"6px"}}>
                                هذا الحقل مطلوب</Typography>}
                        </Box>
                        <Box sx={{marginBottom:"20px"}}>
                            <InputLabel sx={{marginBottom:"6px",fontSize:"13px"}}>كلمة المرور</InputLabel>
                            <Controller
                            name="password"
                            control={control}
                            render={({ field }) => <TextField type="password" {...field} fullWidth/>}
                            {...register("password", { required: "fullName Address is required" })}
                            />
                            {errors.password?.type === 'required' && <Typography color="error" role="alert" sx={{fontSize:"13px",marginTop:"6px"}}>
                                هذا الحقل مطلوب</Typography>}
                        </Box>
                        <Box sx={{marginBottom:"20px"}}>
                            <InputLabel sx={{marginBottom:"6px",fontSize:"13px"}}>طريقة التوصيل</InputLabel>
                            <Controller
                                name="gender"
                                control={control}
                                render={({ field }) =><FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    {...register("city", { required: "gender is required" })}
                                >
                                    <MenuItem value={'inside'}>نفس المدينة</MenuItem>
                                    <MenuItem value={'outside'}>خارج المدينة</MenuItem>
                                </Select>
                                </FormControl>
                                }
                            />
                            {errors.gender?.type === 'required' && <Typography color="error" role="alert" sx={{fontSize:"13px",marginTop:"6px"}}>
                                هذا الحقل مطلوب
                                </Typography>}
                        </Box>
                        <Box>
                            <input type="file" id="image" hidden onChange={(e)=>handleChangeImage(e)}/>
                            <Button variant='contained' sx={{textTransform:"capitalize",padding:0,marginBottom:"20px"}}>
                                <Label htmlFor='image'>اختيار صورة</Label>
                            </Button>
                            <Box sx={{marginBottom:"20px"}}>
                            {
                                image&&
                                <Image src={URL.createObjectURL(image)}/>
                            }
                            </Box>
                        </Box>
                        <Box sx={{marginBottom:"30px"}}>
                            <Map height={"400px"} ref={mapRef}/>
                        </Box>
                        {
                            !load?
                            <Button variant='contained'  fullWidth type='submit'
                            sx={{textTransform:"capitalize"}}>حفظ</Button>
                            :
                            <Button variant='contained'  fullWidth
                            sx={{textTransform:"capitalize"}}>حفظ...</Button>
                        }
                    </form>
                    <Typography sx={{marginTop:"12px"}}> لديك حساب؟ <Link style={{color:"blue"}} to={'/login'}>تسجيل الدخول</Link></Typography>
                </Box>
            </Container>
            <Footer/>
        </>
    )
}
