import {useQuery} from 'react-query'

async function getUserOrder(token)
{
    const response = await fetch(`${process.env.REACT_APP_API}api/v1/orders/pending`,{
        headers:{
            "Authorization":token
        }
    })
    return response.json()
}

export const usePendingOrders = (token)=>
{
    return useQuery('get-pending-orders',()=>getUserOrder(token))
}