import {useQuery} from 'react-query'

async function getUserOrder()
{
    const response = await fetch(`${process.env.REACT_APP_API}api/v1/admin/allOpinions`)
    return response.json()
}

export const useOpinions = ()=>
{
    return useQuery('get-opinions',getUserOrder)
}