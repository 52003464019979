import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    order:null,
}

export const adminSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
    saveOrder: (state,action) => {
        state.order = action.payload.order;
    },
    removeOrder:(state)=>{
        state.order = null
        },
    },
    })

// Action creators are generated for each case reducer function
export const { saveOrder ,removeOrder} = adminSlice.actions

export default adminSlice.reducer