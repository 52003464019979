import { Typography } from '@mui/material';
import React, { useEffect, useRef, useState , useImperativeHandle} from 'react'

import MapPicker from 'react-google-map-picker'
import { useForm } from 'react-hook-form';
const DefaultLocation = { lat: 24.774265, lng: 46.738586};
const DefaultZoom = 10;

function Map({height},ref) {
    const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

    const { formState: { errors }, handleSubmit , watch , reset} = useForm({
        defaultValues: {
            title:"",
        }
    });

    const [location, setLocation] = useState(defaultLocation);
    const [zoom, setZoom] = useState(DefaultZoom);

    function handleChangeLocation (lat, lng){
        setLocation({lat:lat, lng:lng});
    }
    
    function handleChangeZoom (newZoom){
        setZoom(newZoom);
    }

    function handleResetLocation(){
        setDefaultLocation({ ... DefaultLocation});
        setZoom(DefaultZoom);
    }

    const address = useRef()

    useImperativeHandle(ref, () => ({
        r : address.current
      }));

    useEffect(() => {
        async function getAddress()
        {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${location.lat}&lon=${location.lng}`)
            const data = await response.json()
            address.current = data.display_name
            reset({title:data.display_name})
        }
        getAddress()
    }, [location]);

    return (
        <div>
    
    <MapPicker defaultLocation={defaultLocation}
        zoom={zoom}
        mapTypeId="roadmap"
        style={{height:height}}
        onChangeLocation={handleChangeLocation} 
        onChangeZoom={handleChangeZoom}
        apiKey='AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8'/>
        <Typography sx={{marginTop:"16px"}}>{watch("title")}</Typography>
            </div>
    )
}


export default  React.forwardRef(Map);