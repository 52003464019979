import { Button, InputLabel, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useSelector , useDispatch} from 'react-redux';
import Map from '../Map';
import { useNavigate } from 'react-router-dom';
import { saveOrder } from '../../redux/orderSlice';

export default function AddOrder() {
    const [load,setLoad] = useState(false)
    const { register,control,formState: { errors }, handleSubmit , getValues} = useForm({
        defaultValues: {
            title:"",
            items:[]
        }
    });

    const {user} = useSelector(s => s.user);
    const navigate = useNavigate();
    const dispacth = useDispatch();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const mapRef1 = useRef(null);
    const mapRef2 = useRef(null);

    const { fields, append, remove} = useFieldArray({
        control,
        name: "items"
      });

      function addNewNote()
      {
            append({title:''})
      }

      async function onSubmit(data)
      {
          closeSnackbar();
          const address = mapRef1.current.r;
          const address2 = mapRef2.current.r;
          const notes = data.items.map(da => da?.title)
          if(!user){
            dispacth(saveOrder({order:{title:data.title,notes:notes , senderAddress:address , receiverAddress :address2}}))
            navigate('/login?redirect=order')
            return;
          }
          setLoad(true)
          try{
                const response = await fetch(`${process.env.REACT_APP_API}api/v1/orders/add`,{
                    method:"POST",
                    headers:{
                        "Authorization" : user.token,
                        "Content-Type":"application/json"
                    },
                    body:JSON.stringify({title:data.title,notes:notes , senderAddress:address , receiverAddress :address2})
                })
                const resData = await response.json()
                console.log(resData);
                if(response.status!==200&&response.status!==201)
                {
                    enqueueSnackbar(resData.message,{variant:"error", autoHideDuration:2000})
                    setLoad(false)
                    throw new Error('failed occured')
                }
                  enqueueSnackbar(resData.msg,{variant:"success", autoHideDuration:2000})
                setLoad(false)
          }
        catch(err)
        {
            setLoad(false);
            console.log(err)
        }
      }

  return (
    <Box >
    <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{marginBottom:"20px"}}>
            <InputLabel sx={{marginBottom:"6px",fontSize:"15px"}}>تفاصيل الشحنة</InputLabel>
            <Controller
            name="title"
            control={control}
            render={({ field }) => <TextField type="text" autoComplete='off' {...field} fullWidth/>}
            {...register("title", { required: "fullName Address is required" })}
            />
            {errors.title?.type === 'required' && <Typography color="error" role="alert" sx={{fontSize:"13px",marginTop:"6px"}}>
                هذا الحقل مطلوب</Typography>}
        </Box>
        <Box>
        {
                fields.map((item,index)=>
                {
                    return(
                        <Box  key={index+'po1'}
                        sx={{marginBottom:"20px",alignItems:"center"}}>
                            <Box>
                                <InputLabel sx={{marginBottom:"6px",fontSize:"15px"}}>ملاحظة {index+1}</InputLabel>
                                <TextField defaultValue={item.title} fullWidth  multiline rows={3}
                                {...register(`items.${index}.title`)}/>
                            </Box>
                            <Box item xs={2}>
                                <Button color="error" onClick={()=>remove(index)}>حذف</Button>
                            </Box>
                        </Box> 
                    )
                })
            }
            <Button sx={{fontSize:"16px",marginY:"10px"}}
            onClick={addNewNote}>إضافة ملاحظة</Button>
        </Box>
        <Box sx={{marginBottom:"30px"}}>
            <InputLabel sx={{marginBottom:"12px",fontSize:"15px"}}>ادخل موقع الاستلام:</InputLabel>
            <Map height={"400px"} ref={mapRef1}/>
        </Box>
        <Box sx={{marginBottom:"30px"}}>
            <InputLabel sx={{marginBottom:"12px",fontSize:"15px"}}>ادخل موقع التسليم:</InputLabel>
            <Map height={"400px"} ref={mapRef2}/>
        </Box>
        {
            !load?
            <Button variant='contained'  fullWidth type='submit'
            sx={{textTransform:"capitalize"}}>تأكيد الطلب</Button>
            :
            <Button variant='contained'  fullWidth
            sx={{textTransform:"capitalize"}}>جاري التاكيد...</Button>
        }
    </form>
    </Box>
  )
}
