import React from 'react'
import {Box,Container,Typography} from '@mui/material'
import LinesDesign from './LinesDesign'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import Client from './Client';
import { useOpinions } from '../../hooks/useOpinions';
import Opinion from '../skelton/Opinion';

export default function Clients() {
    const {data , isLoading} = useOpinions();
    console.log(data);
    return (
        <Box sx={{backgroundColor:"#f1f1f1",paddingY:"60px"}}>
            <Container>
                <Box sx={{marginBottom:"36px"}}>
                    <Typography sx={{fontSize:"28px",textAlign:"start",fontWeight:"700",marginBottom:"6px"}}>آراء العملاء</Typography>
                    <LinesDesign/>
                </Box>
                {
                    isLoading
                    ?
                    <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{
                    clickable: true,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <Opinion/>
                    </SwiperSlide>
                    <SwiperSlide><Opinion/></SwiperSlide>
                    <SwiperSlide><Opinion/></SwiperSlide>
                    <SwiperSlide><Opinion/></SwiperSlide>
                    <SwiperSlide><Opinion/></SwiperSlide>
                </Swiper>
                :
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{
                    clickable: true,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    {
                        data?.opinions.map(op=>{
                            return <SwiperSlide key={op.id}><Client user={op}/></SwiperSlide>
                        })
                    }
                </Swiper>
                }
            </Container>
        </Box>
    )
}
