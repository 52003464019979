import React from 'react'
import Services from '../components/home/Services'
import Slider from '../components/home/Slider'
import Navbar from '../components/Navbar'
import Clients from '../components/home/Clients'
import Footer from '../components/Footer'

export default function Home() {
  return (
    <div>
        <Navbar/>
        <Slider/>
        <Services/>
        <Clients/>
        <Footer/>
    </div>
  )
}
