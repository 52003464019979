import { Container, Typography,styled, Grid, Box } from '@mui/material'
import React from 'react'
import image from '../../images/services.avif'
import LinesDesign from './LinesDesign'

const Image = styled("img")({
    width:"100%"
})

export default function Services() {
    return (
        <Container sx={{marginY:"60px"}}>
            <Box sx={{marginBottom:"12px"}}>
                <Typography sx={{fontSize:"28px",textAlign:"start",fontWeight:"700",marginBottom:"6px"}}>خدماتنا</Typography>
                <LinesDesign/>
            </Box>
            <Grid container justifyContent="center" spacing={2} alignItems="center">
                <Grid item xs={4}>
                    <Image src={image}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{fontSize:"22px",fontWeight:"700",marginBottom:"16px"}}>أوجد منتجك</Typography>
                    <Typography sx={{fontSize:"15px",color:"#747579"}}>
                    لوريم إيبسوم طريقة لكتابة النصوص في النشر والتصميم الجرافيكي تستخدم بشكل شائع لتوضيح الشكل
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}
