import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logoutAdmin } from '../../redux/adminSlice';

const drawerWidth = 240;

const topics = [
    // {
    //     title:"الصفحة الرئيسية",
    //     link:"",
    //     icon:<HomeOutlinedIcon/>
    // },
    {
        title:"المستخدمين",
        link:"/",
        icon:<GroupOutlinedIcon/>
    },
    {
        title:"الطلبات",
        link:"/orders",
        icon:<ShoppingBagOutlinedIcon/>
    },
    {
        title:"إضافة أراء",
        link:"/add-opinions",
        icon:<AddCircleOutlineOutlinedIcon/>
    },
]

function AdminLayout(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const dispatch = useDispatch();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
        <Toolbar />
        <Divider />
        <List>
            {topics.map((text, index) => (
            <Link to={`/admin${text.link}`}>
                <ListItem key={text} disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                        {text.icon}
                    </ListItemIcon>
                    <ListItemText primary={text.title} />
                    </ListItemButton>
                </ListItem>
            </Link>
            ))}
        </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
            position="fixed"
            sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            }}
        >
            <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
            >
                <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{color:"white" , flex:1}}>
                لوحة التحكم
            </Typography>
            <Button sx={{color:"white"}} onClick={()=>dispatch(logoutAdmin())}>
                تسجيل الخروج
            </Button>
            </Toolbar>
        </AppBar>
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            >
            {drawer}
            </Drawer>
            <Drawer
            variant="permanent"
            sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
            >
            {drawer}
            </Drawer>
        </Box>
        <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } , overflowX:"auto"}}
        >
            <Toolbar />
            {
                props.children
            }
        </Box>
        </Box>
    );
}

AdminLayout.propTypes = {
    window: PropTypes.func,
};

export default AdminLayout;