import React from 'react'
import AdminLayout from '../../components/admin/AdminLayout'

export default function AdminHome() {
    return (
        <AdminLayout>
            Home
        </AdminLayout>
    )
}
