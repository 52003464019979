import { Box, Container, Grid, List, ListItem, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons';


export default function Footer() {
  return (
    <Box component={"footer"} sx={{backgroundColor:"#005B8E" , color:"black" , paddingY:"50px"}}>
        <Container>
            <Grid container>
                {/* <Grid item xs={12}>
                    <Typography sx={{marginBottom:"14px"}}>روابط</Typography>
                    <List sx={{margin:0 , padding:0}}>
                        <ListItem sx={{padding:0 , marginBottom:"10px"}}>
                            <Link to={"/login"}>
                            تسجيل الدخول
                            </Link>
                        </ListItem>
                        <ListItem sx={{padding:0 ,  marginBottom:"10px"}}>
                            <Link to={"/register"}>
                            إنشاء حساب
                            </Link>
                        </ListItem>
                    </List>
                </Grid> */}
                <Grid item xs={12} sx={{display:"flex" , justifyContent:"center"}}>
                    <List sx={{display:"flex" , gap:"10px" , width:"fit-content"}}>
                        <ListItem sx={{margin:0 , padding:0}}>
                            <SocialIcon url="https://www.facebook.com/" bgColor='white' target={"_blank"} style={{width:"40px" , height:"40px"}}/>
                        </ListItem>
                        <ListItem sx={{margin:0 , padding:0}}>
                            <SocialIcon url="https://www.instagram.com/" bgColor='white' target={"_blank"} style={{width:"40px" , height:"40px"}}/>
                        </ListItem>
                        <ListItem sx={{margin:0 , padding:0}}>
                            <SocialIcon url="https://web.whatsapp.com/" bgColor='white' target={"_blank"} style={{width:"40px" , height:"40px"}}/>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Container>
    </Box>
  )
}
