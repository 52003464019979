import { Box, Button, Container,InputLabel,  Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import { useForm, Controller } from "react-hook-form";
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import {useDispatch, useSelector} from 'react-redux'
import { loginUser } from '../../redux/userSlice';
import { useSnackbar } from 'notistack';
import { removeOrder } from '../../redux/orderSlice';


export default function UserLogin() {
    const navigate = useNavigate()

    const [load,setLoad] = useState(false)
    const { register,control,formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            phone:"",
            password:"",
        }
    });

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {order} = useSelector(s =>s.order);
    console.log(order);
    const dipsatch = useDispatch();

    async function onSubmit(data)
    {
        closeSnackbar();
        setLoad(true)
        const formData = new FormData();
        formData.append('phone' , data.phone);
        formData.append('password' , data.password);
        try{
            const response = await fetch(`${process.env.REACT_APP_API}api/v1/auth/login`,{
                method:"POST",
                body:formData
            })
            const resData = await response.json()
            if(response.status!==200&&response.status!==201)
            {
                enqueueSnackbar(resData.message,{variant:"error", autoHideDuration:2000})
                setLoad(false)
                throw new Error('failed occured')
            }
            enqueueSnackbar("نجح تسجيل الدخول",{variant:"success", autoHideDuration:2000})
            dipsatch(loginUser({user:resData.user}))
            if(order){
                await createOrder(resData.user.token);
            }
            navigate('/user/profile')
            setLoad(false)
        }
        catch(err)
        {
            setLoad(false);
            console.log(err)
        }
    }

    async function createOrder(token)
      {
          closeSnackbar();
          try{
                const response = await fetch(`${process.env.REACT_APP_API}api/v1/orders/add`,{
                    method:"POST",
                    headers:{
                        "Authorization" : token,
                        "Content-Type":"application/json"
                    },
                    body:JSON.stringify({...order})
                })
                const resData = await response.json()
                console.log(resData);
                if(response.status!==200&&response.status!==201)
                {
                    enqueueSnackbar(resData.message,{variant:"error", autoHideDuration:2000})
                    setLoad(false)
                    throw new Error('failed occured')
                }
                enqueueSnackbar(resData.msg,{variant:"success", autoHideDuration:2000})
                dipsatch(removeOrder());
          }
        catch(err)
        {
            setLoad(false);
            console.log(err)
        }
      }


    return (
        <>
        <Navbar/>
            <Container sx={{marginTop:"90px" , minHeight:"68vh"}}>
                <Box sx={{width:{md:"650px"},padding:"30px 50px",margin:"30px auto 60px"}}>
                    <Typography variant='h5' marginBottom={"30px"}>تسجيل الدخول</Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{marginBottom:"20px"}}>
                            <InputLabel sx={{marginBottom:"6px",fontSize:"13px"}}>رقم الجوال</InputLabel>
                            <Controller
                            name="phone"
                            control={control}
                            render={({ field }) => <TextField type="text" autoComplete='off' {...field} fullWidth/>}
                            {...register("phone", { required: "fullName Address is required" })}
                            />
                            {errors.phone?.type === 'required' && <Typography color="error" role="alert" sx={{fontSize:"13px",marginTop:"6px"}}>
                                هذا الحقل مطلوب</Typography>}
                        </Box>
                        <Box sx={{marginBottom:"20px"}}>
                            <InputLabel sx={{marginBottom:"6px",fontSize:"13px"}}>كلمة المرور</InputLabel>
                            <Controller
                            name="password"
                            control={control}
                            render={({ field }) => <TextField type="password" {...field} fullWidth/>}
                            {...register("password", { required: "fullName Address is required" })}
                            />
                            {errors.password?.type === 'required' && <Typography color="error" role="alert" sx={{fontSize:"13px",marginTop:"6px"}}>
                                هذا الحقل مطلوب</Typography>}
                        </Box>
                        {
                            !load?
                            <Button variant='contained'  fullWidth type='submit'
                            sx={{textTransform:"capitalize"}}>تسحيل الدخول</Button>
                            :
                            <Button variant='contained'  fullWidth
                            sx={{textTransform:"capitalize" , opacity:0.7}}>جاري التسجيل...</Button>
                        }
                    </form>
                    <Typography sx={{marginTop:"12px"}}>ليس لديك حساب؟ <Link style={{color:"blue"}} to={'/register'}>إنشاء حساب</Link></Typography>
                </Box>
            </Container>
            <Footer/>
        </>
    )
}
