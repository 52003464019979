import { Box, Button, Container,InputLabel, TextField, Typography } from '@mui/material'
import React from 'react'
import { useForm, Controller } from "react-hook-form";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import {useSnackbar} from 'notistack'
import {useDispatch} from 'react-redux'
import {loginAdmin} from '../../redux/adminSlice'

export default function AdminLogin() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {enqueueSnackbar,closeSnackbar} = useSnackbar()
    const [load,setLoad] = useState(false)
    const { register,control,formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            phone:"",
            password:"",
        }
    });

    async function onSubmit(data)
    {
        setLoad(true)
        closeSnackbar()
        try{
            const response = await fetch(`${process.env.REACT_APP_API}api/v1/auth/loginAdmin`,{
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({phone:data.phone,password:data.password})
            })
            const resData = await response.json()
            setLoad(false)
            console.log(resData)
            if(response.status!==200&&response.status!==201)
            {
                enqueueSnackbar(resData.message,{variant:"error",autoHideDuration:8000})
                throw new Error('failed occured')
            }
            dispatch(loginAdmin({admin:resData.user}))
            navigate('/admin')
        }
        catch(err)
        {
            console.log(err)
        }
    }


    return (
        <>
            <Container sx={{marginTop:"90px" , minHeight:"68vh"}}>
                <Box sx={{width:{md:"650px"},padding:"30px 50px",margin:"30px auto 60px"}}>
                    <Typography variant='h5' marginBottom={"30px"}>تسجيل الدخول</Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{marginBottom:"20px"}}>
                            <InputLabel sx={{marginBottom:"6px",fontSize:"13px"}}>رقم الجوال</InputLabel>
                            <Controller
                            name="phone"
                            control={control}
                            render={({ field }) => <TextField type="text" autoComplete='off' {...field} fullWidth/>}
                            {...register("phone", { required: "fullName Address is required" })}
                            />
                            {errors.phone?.type === 'required' && <Typography color="error" role="alert" sx={{fontSize:"13px",marginTop:"6px"}}>
                                هذا الحقل مطلوب</Typography>}
                        </Box>
                        <Box sx={{marginBottom:"20px"}}>
                            <InputLabel sx={{marginBottom:"6px",fontSize:"13px"}}>كلمة المرور</InputLabel>
                            <Controller
                            name="password"
                            control={control}
                            render={({ field }) => <TextField type="password" {...field} fullWidth/>}
                            {...register("password", { required: "fullName Address is required" })}
                            />
                            {errors.password?.type === 'required' && <Typography color="error" role="alert" sx={{fontSize:"13px",marginTop:"6px"}}>
                                هذا الحقل مطلوب</Typography>}
                        </Box>
                        {
                            !load?
                            <Button variant='contained'  fullWidth type='submit'
                            sx={{textTransform:"capitalize"}}>تسحيل الدخول</Button>
                            :
                            <Button variant='contained'  fullWidth
                            sx={{textTransform:"capitalize"}}>جاري التسجيل...</Button>
                        }
                    </form>
                </Box>
            </Container>
            <Footer/>
        </>
    )
}
