import { Avatar, Box, Paper, Typography } from '@mui/material'
import React from 'react'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

export default function Client({user}) {
    return (
        <Paper sx={{padding:"40px 20px"}}>
            <Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                {/* <Avatar src={'https://mui.com/static/images/avatar/2.jpg'} sx={{width:"85px",height:"85px"}}/> */}
                <Typography sx={{fontSize:"18px",color:"black",fontWeight:"600",marginTop:"16px"}}>{user?.username}</Typography>
                <Typography sx={{fontSize:"14px",color:"#747579",textAlign:"center",marginTop:"12px"}}>
                <FormatQuoteIcon/>{user?.description}<FormatQuoteIcon/>
                </Typography>
            </Box>
        </Paper>
    )
}
