import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import AddOrder from '../components/user/AddOrder'
import { Container } from '@mui/material'

export default function CreateOrder() {
  return (
    <>
    <Navbar/>
    <Container sx={{marginBottom:"50px" , marginTop:"100px"}}>
        <AddOrder/>
    </Container>
    <Footer/>
    </>
  )
}
