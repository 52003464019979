import './App.css';
import {createTheme,ThemeProvider} from '@mui/material'
import {Route , Routes , Navigate} from 'react-router-dom'
import Home from './pages/Home';
import UserLogin from './pages/auth/UserLogin';
import UserRegister from './pages/auth/UserRegister';
import AdminHome from './pages/admin/AdminHome';
import AdminUser from './pages/admin/AdminUser';
import UserVerifyCode from './pages/auth/UserVerifyCode';
import UserProfile from './pages/UserProfile';
import AdminOrders from './pages/admin/AdminOrders';
import AddOpinion from './pages/admin/AddOpinion';
import Location from './pages/Location';
import AdminLogin from './pages/auth/AdminLogin';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query'
import CreateOrder from './pages/CreateOrder';

const queryClient = new QueryClient()

function App() {

  const theme = createTheme({
    direction:"rtl",
    palette:{
      primary:{
        main:"#005B8E",
        contrastText:"#fff"
      },
    }
  })

  const {admin} = useSelector((state)=>state.admin)
  const {user} = useSelector((state)=>state.user)

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/login' element={user? <Navigate to={"/"}/> :<UserLogin/>}/>
            <Route path='/register' element={user? <Navigate to={"/"}/> :<UserRegister/>}/>
            <Route path='/verifyCode' element={user? <Navigate to={"/"}/> :<UserVerifyCode/>}/>
            <Route path='/user/profile' element={!user? <Navigate to={"/login"}/> :<UserProfile/>}/>

            {/* admin pages */}
            <Route path='admin' element={!admin? <Navigate to={"/admin/login"}/> :<AdminUser/>}/>
            <Route path='admin/orders' element={!admin? <Navigate to={"/admin/login"}/> :<AdminOrders/>}/>
            <Route path='admin/add-opinions' element={!admin? <Navigate to={"/admin/login"}/> :<AddOpinion/>}/>

            {/* admin pages */}
            <Route path='admin' element={<AdminHome/>}/>
            <Route path='admin/login' element={admin? <Navigate to={"/admin"}/> :<AdminLogin/>}/>
            <Route path='admin/users' element={<AdminUser/>}/> 

            <Route path='/create-order' element={<CreateOrder/>}/>
          </Routes>
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
