import React, { useState } from 'react'
import AdminLayout from '../../components/admin/AdminLayout'
import { useForm, Controller } from "react-hook-form";
import { Box } from '@mui/system';
import { Button, InputLabel, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

export default function AddOpinion() {
    const [load,setLoad] = useState(false)
    const { register,control,formState: { errors }, handleSubmit , reset } = useForm({
        defaultValues: {
            name:"",
            content:"",
        }
    });


    const {admin} = useSelector(s => s.admin);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    async function onSubmit(data)
    {
        closeSnackbar();
        setLoad(true)
        try{
              const response = await fetch(`${process.env.REACT_APP_API}api/v1/admin/addOpinion`,{
                  method:"POST",
                  headers:{
                      "Authorization" : admin.token,
                      "Content-Type":"application/json"
                  },
                  body:JSON.stringify({username:data.name, description:data.content})
              })
              const resData = await response.json()
              console.log(resData);
              if(response.status!==200&&response.status!==201)
              {
                  enqueueSnackbar(resData.message,{variant:"error", autoHideDuration:2000})
                  setLoad(false)
                  throw new Error('failed occured')
              }
                enqueueSnackbar(resData.msg,{variant:"success", autoHideDuration:2000});
                reset({name:"" , content:""})
              setLoad(false)
        }
      catch(err)
      {
          setLoad(false);
          console.log(err)
      }
    }

    return (
        <AdminLayout>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{width:"450px",maxWidth:"100%",marginTop:"15px"}}>
                        <Box sx={{marginBottom:"20px"}}>
                            <InputLabel sx={{marginBottom:"10px",fontSize:"13px"}}>الإسم بالكامل</InputLabel>
                            <Controller
                            name="name"
                            control={control}
                            render={({ field }) => <TextField type="text" autoComplete='off' {...field} fullWidth/>}
                            {...register("name", { required: "name Address is required" })}
                            />
                            {errors.name?.type === 'required' && <Typography color="error" role="alert" sx={{fontSize:"13px",marginTop:"6px"}}>
                            هذا الحقل مطلوب</Typography>}
                        </Box>
                    </Box>
                    <Box sx={{width:"450px",maxWidth:"100%",marginTop:"15px"}}>
                        <Box sx={{marginBottom:"20px"}}>
                            <InputLabel sx={{marginBottom:"10px",fontSize:"13px"}}>الوصف</InputLabel>
                            <Controller
                            name="content"
                            control={control}
                            render={({ field }) => <TextField multiline rows={3} type="text" autoComplete='off' {...field} fullWidth/>}
                            {...register("content", { required: "content Address is required" })}
                            />
                            {errors.content?.type === 'required' && <Typography color="error" role="alert" sx={{fontSize:"13px",marginTop:"6px"}}>
                            هذا الحقل مطلوب</Typography>}
                        </Box>
                        {
                            load
                            ?
                            <Button sx={{opacity:""}} variant='contained'>حفظ...</Button>
                            :
                            <Button type='submit' variant='contained'>حفظ</Button>
                        }
                    </Box>
                </form>
        </AdminLayout>
    )
}
