import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    admin:null,
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
    loginAdmin: (state,action) => {
        state.admin = action.payload.admin;
    },
    logoutAdmin:(state)=>{
        state.admin = null
        },
    },
    })

// Action creators are generated for each case reducer function
export const { loginAdmin ,logoutAdmin} = adminSlice.actions

export default adminSlice.reducer