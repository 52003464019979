import React from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useUserOrders } from '../../hooks/useUserOrder';
import { useSelector } from 'react-redux';


const columns = [
    { id: 'name_student', label: 'إسم الطلب', minWidth: 150 },
    { id: 'student_email', label: 'الحالة', minWidth: 150 },
  ];
  


export default function UserOrders() {

    const {user} = useSelector(s => s.user);
    const {data } = useUserOrders(user?.token);

    const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
        {
            false
            ?
            <h1>load</h1>
            :
            <Paper sx={{ width: '100%'  , marginTop:"30px"}}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableRow sx={{backgroundColor:"#005B8E" , color:"white"}}>
                        {columns.map((column) => (
                            <TableCell
                            key={column.id}
                            align={"center"}
                            style={{ top: 57, minWidth: column.minWidth }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    <TableBody>
                        {data?.orders?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return <TableRow hover role="checkbox"  key={row.id+"demj"}>
                                <TableCell align='center'>
                                    {row.title}
                                </TableCell>
                                <TableCell align='center'sx={{color:row.status=="Pending"?"#ed6c02":row.status=="Accepted"?"#2e7d32":"#d32f2f"}}>
                                    {row?.status=="Pending"?"معلق":row.status=="Accepted"?"مقبول":"مرفوض"}
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    count={data?.orders?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        }
    </>
  )
}
