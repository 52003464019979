import { Button, DialogActions, DialogContent, Box, DialogTitle, InputLabel, TextField, Typography , styled} from '@mui/material'
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';


const Form = styled('form')({
    width:"500px",
    maxWidth:"100%"
})

export default function EditUserDialog({handleClose,user}) {
    const { register,control,formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            password:"",
        }
    });

    const {admin} = useSelector(s => s.admin);
    const {enqueueSnackbar,closeSnackbar} = useSnackbar()
    const [load,setLoad] = useState(false)

    async function onSubmit(data)
    {
        setLoad(true)
        closeSnackbar()
        console.log({userId:user.id,password:data.password});
        try{
            const response = await fetch(`${process.env.REACT_APP_API}api/v1/admin/resetPassword`,{
                method:"POST",
                headers:{
                    "Authorization" : admin.token,
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({userId:user.id,password:data.password})
            })
            const resData = await response.json()
            setLoad(false)
            console.log(resData)
            if(response.status!==200&&response.status!==201)
            {
                enqueueSnackbar(resData?.message,{variant:"error",autoHideDuration:8000})
                throw new Error('failed occured')
            }
            enqueueSnackbar(resData?.message,{variant:"success",autoHideDuration:8000})
            handleClose();
        }
        catch(err)
        {
            setLoad(false);
            console.log(err)
        }
    }

  return (
    <>
    <DialogTitle>تعديل كلمة المرور</DialogTitle>
    <Form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
            <Box sx={{marginBottom:"20px"}}>
                <InputLabel sx={{marginBottom:"6px",fontSize:"13px"}}>كلمة المرور</InputLabel>
                <Controller
                name="password"
                control={control}
                render={({ field }) => <TextField type="password" {...field} fullWidth/>}
                {...register("password", { required: "fullName Address is required" })}
                />
                {errors.password?.type === 'required' && <Typography color="error" role="alert" sx={{fontSize:"13px",marginTop:"6px"}}>
                    هذا الحقل مطلوب</Typography>}
            </Box>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="error">إغلاق</Button>
            {
                load
                ?
                <Button sx={{opacity:0.7}}>جاري التاكيد...</Button>
                :
                <Button type="submit">تأكيد</Button>
            }
        </DialogActions>
    </Form>
    </>
  )
}
