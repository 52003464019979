import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Dialog, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { usePendingOrders } from '../../hooks/usePendingOrders';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import OrderNotesDialog from './OrderNotesDialog';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export default function PendingOrders() {
    const columns = [
    { id: 'name_course', label:"اسم الطلب", minWidth: 150 },
    { id: 'name_subject', label:"اسم المستخدم", minWidth: 150 },
    { id: 'update', label:"عنوان المرسل", minWidth: 150 },
    { id: 'update', label:"عنوان المرسل إليه", minWidth: 150 },
    { id: 'update', label:"قبول", minWidth: 150 },
    { id: 'update', label:"رفض", minWidth: 150 },
    { id: 'update', label:"ملاحظات", minWidth: 150 }];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const {admin} = useSelector(s => s.admin)
    const {data} = usePendingOrders(admin.token);

    const [orders,setOrders] = useState([]);

    useEffect(()=>{
        data && setOrders(data?.orders);
    },[data])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const filterOrder = (id) => {
        setOrders(pre=>pre.filter(order=>order.id != id));
    }


    async function handelChangeStatus(orderId , status)
    {
        console.log(status);
        filterOrder(orderId)
        closeSnackbar();
        try{
              const response = await fetch(`${process.env.REACT_APP_API}api/v1/orders/status`,{
                  method:"POST",
                  headers:{
                      "Authorization" : admin.token,
                      "Content-Type":"application/json"
                  },
                  body:JSON.stringify({id:orderId , status:status})
              })
              const resData = await response.json()
              console.log(resData);
              if(response.status!==200&&response.status!==201)
              {
                  enqueueSnackbar(resData.message,{variant:"error", autoHideDuration:2000})
                  throw new Error('failed occured')
              }
                enqueueSnackbar(resData.msg,{variant:"success", autoHideDuration:2000})
        }
      catch(err)
      {
          console.log(err)
      }
    }
    const [open , setOpen] = useState(null)


    return (
    <Box>
        <Paper sx={{ width: '100%',padding:"20px"}}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                            key={column.id}
                            align={"center"}
                            style={{ top: 57, minWidth: column.minWidth }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    <TableBody>
                        {orders?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return <TableRow hover role="checkbox"  key={row.id+"demjwsnbhs"}>
                                <TableCell align='center'>
                                    {row?.title}
                                </TableCell>
                                <TableCell align='center'>
                                    {row?.User?.username}
                                </TableCell>
                                <TableCell align='center'>
                                    {row?.senderAddress}
                                </TableCell>
                                <TableCell align='center'>
                                    {row?.receiverAddress}
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton color="success" onClick={()=>handelChangeStatus(row.id , "Accepted")}>
                                        <CheckIcon/>
                                    </IconButton>
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton color='error' onClick={()=>handelChangeStatus(row.id , "Rejected")}>
                                        <CloseIcon/>
                                    </IconButton>
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton onClick={()=>setOpen(row.id)}>
                                        <RemoveRedEyeIcon/>
                                    </IconButton>
                                </TableCell>
                                <Dialog open={open===row.id} onClose={()=>setOpen(null)}>
                                    <OrderNotesDialog order={row} handleClose={()=>setOpen(null)}/>
                                </Dialog>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={orders?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
        </Paper>
    </Box>
)
}