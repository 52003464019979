import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Dialog, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useRejectedOrders } from '../../hooks/useRejectedOrder';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import OrderNotesDialog from './OrderNotesDialog';

export default function RejectedOrders() {
    const columns = [
    { id: 'name_course', label:"اسم الطلب", minWidth: 150 },
    { id: 'name_subject', label:"اسم المستخدم", minWidth: 150 },
    { id: 'update', label:"عنوان المرسل", minWidth: 150 },
    { id: 'update', label:"عنوان المرسل إليه", minWidth: 150 },
    { id: 'update', label:"ملاحظات", minWidth: 150 }];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    
    const {admin} = useSelector(s => s.admin)
    const {data} = useRejectedOrders(admin.token);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [open , setOpen] = useState(null)

    return (
    <Box>
        <Paper sx={{ width: '100%',padding:"20px"}}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                            key={column.id}
                            align={"center"}
                            style={{ top: 57, minWidth: column.minWidth }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    <TableBody>
                        {data?.orders?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return <TableRow hover role="checkbox"  key={row.id+"demj"}>
                                <TableCell align='center'>
                                    {row?.title}
                                </TableCell>
                                <TableCell align='center'>
                                    {row?.User?.username}
                                </TableCell>
                                <TableCell align='center'>
                                    {row?.senderAddress}
                                </TableCell>
                                <TableCell align='center'>
                                    {row?.receiverAddress}
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton onClick={()=>setOpen(row.id)}>
                                        <RemoveRedEyeIcon/>
                                    </IconButton>
                                </TableCell>
                                <Dialog open={open===row.id} onClose={()=>setOpen(null)}>
                                    <OrderNotesDialog order={row} handleClose={()=>setOpen(null)}/>
                                </Dialog>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data?.orders?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
        </Paper>
    </Box>
)
}